<template>
  <svg
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.9135 5.38727L4.16003 4.6338C3.77012 4.2439 3.77012 3.61174 4.16003 3.22183C4.54993 2.83193 5.1821 2.83193 5.572 3.22183L6.32324 3.97305C7.61708 2.93456 9.20392 2.24713 10.9398 2.05493V0.998413C10.9398 0.447004 11.3868 0 11.9382 0C12.4896 0 12.9366 0.447004 12.9366 0.998413V2.05493C14.6725 2.24713 16.2593 2.93456 17.5532 3.97305L18.3044 3.22183C18.6943 2.83193 19.3265 2.83193 19.7164 3.22183C20.1063 3.61174 20.1063 4.2439 19.7164 4.6338L18.9629 5.38727C19.9951 6.68152 20.6782 8.26733 20.8693 10.0016H21.9398C22.4912 10.0016 22.9382 10.4486 22.9382 11C22.9382 11.5514 22.4912 11.9984 21.9398 11.9984H20.8693C20.6782 13.7327 19.9951 15.3185 18.9629 16.6127L19.7164 17.3662C20.1063 17.7561 20.1063 18.3883 19.7164 18.7782C19.3265 19.1681 18.6943 19.1681 18.3044 18.7782L17.5532 18.0269C16.2593 19.0654 14.6725 19.7529 12.9366 19.9451V21.0016C12.9366 21.553 12.4896 22 11.9382 22C11.3868 22 10.9398 21.553 10.9398 21.0016V19.9451C9.20392 19.7529 7.61708 19.0654 6.32324 18.0269L5.572 18.7782C5.1821 19.1681 4.54993 19.1681 4.16003 18.7782C3.77012 18.3883 3.77012 17.7561 4.16003 17.3662L4.9135 16.6127C3.88131 15.3185 3.19823 13.7327 3.00713 11.9984H1.93662C1.38521 11.9984 0.938202 11.5514 0.938202 11C0.938202 10.4486 1.38521 10.0016 1.93662 10.0016H3.00713C3.19823 8.26733 3.88131 6.68152 4.9135 5.38727ZM11.9382 18C15.7981 18 18.9271 14.866 18.9271 11C18.9271 7.13401 15.7981 4 11.9382 4C8.07834 4 4.9493 7.13401 4.9493 11C4.9493 14.866 8.07834 18 11.9382 18ZM11.9382 14C10.284 14 8.94296 12.6569 8.94296 11C8.94296 9.34315 10.284 8 11.9382 8C13.5924 8 14.9334 9.34315 14.9334 11C14.9334 12.6569 13.5924 14 11.9382 14ZM11.9382 12C12.4896 12 12.9366 11.5523 12.9366 11C12.9366 10.4477 12.4896 10 11.9382 10C11.3868 10 10.9398 10.4477 10.9398 11C10.9398 11.5523 11.3868 12 11.9382 12Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'Control',
}
</script>
